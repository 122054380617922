<template>
  <div class="flix-container flix-form-group">
    <div class="flix-row">
      <div class="flix-col-md-4 flix-col-md-flix-offset-4">
        <div class="flix-well flix-text-center">
          <div class="flix-form-group">
            <div class="flix-html-h1">
              <flixIcon :id="'plus'" />
            </div>
            <div class="flix-html-h2">
              {{ $t('message.signUp') }}
            </div>
          </div>
          <div class="flix-form-group">{{ $t('message.signUpInfo') }}</div>
          <a class="flix-btn flix-btn-success" :href="getQuickstartUrl()">
            <flixIcon id="calendar"></flixIcon>
            {{ $t('message.quickstart') }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    callback: Function
  },
  data() {
    return {}
  },
  watch: {},
  methods: {
    getQuickstartUrl() {
      return (
        'https://bookingflix.' +
        (this.$i18n.locale === 'en' ? 'net' : this.$i18n.locale) +
        '/quickstart'
      )
    }
  },
  mounted() {},
  computed: {}
}
</script>
<style lang="sass" scoped></style>
